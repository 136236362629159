import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import KeyDetails from "../components/KeyDetails";

const IndexPage = ({ data, location }) => {
  return (
    <Layout>
      <SEO title="Home" description="Bardem" location={location} />
      <KeyDetails details={data.allPrismicHome.edges[0].node.data} />
    </Layout>
  );
};

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

export const pageHomeQuery = graphql`
  query {
    allPrismicHome {
      edges {
        node {
          data {
            opening_hours {
              text
            }
            address {
              raw
            }
            address_display_text {
              text
            }
            email {
              raw
            }
            email_display_text {
              text
            }
            phone_number {
              raw
            }
            menu_display_text {
              raw
            }
            menu_link {
              url
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
