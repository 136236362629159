import React from "react";
import Img from "gatsby-image";

class ImageScrollerMobile extends React.Component {
  state = {
    length: 10
  };
  imageWidthCheck = (width, height) => {
    if (width < height) {
      return "portrait";
    } else {
      return "landscape";
    }
  };
  render() {
    const { imageArray } = this.props;
    const { length } = this.state;
    return (
      <div className="relative z-20 px-2">
        <div
          className="relative"
          style={{ minHeight: `${100 * (length + 1)}vh` }}
        >
          <div className=" mobile-image-spacer"></div>
          {imageArray.map((item, index) => {
            return (
              <div
                key={index}
                className="min-h-screen flex items-center justify-center"
              >
                <div
                  key={index}
                  className={
                    "scroller-image w-full relative z-40 my-32  " +
                    this.imageWidthCheck(
                      item.image.localFile.childImageSharp.fluid
                        .presentationWidth,
                      item.image.localFile.childImageSharp.fluid
                        .presentationHeight
                    )
                  }
                >
                  <Img
                    key={index}
                    alt={item.image.alt}
                    fluid={item.image.localFile.childImageSharp.fluid}
                  />
                </div>
              </div>
            );
          })}
          <div className="min-h-screen flex items-center justify-center"></div>
        </div>
      </div>
    );
  }
}

export default ImageScrollerMobile;
