import React from "react";

class KeyDetails extends React.Component {
  removeSpaces = str => {
    str = str.replace(/\s/g, "");
    return str;
  };
  render() {
    const { details } = this.props;
    return (
      <div className="absolute z-10 inset-0 key-details">
        <div className=" absolute left-0 top-0 leading-very-tight open-details">
          <span
            className="text-base inline-block relative z-30"
            dangerouslySetInnerHTML={{ __html: details.opening_hours.text }}
          ></span>
        </div>
        <div className="absolute right-0 leading-very-tight">
          <div className="email email-holder lg:absolute z-30">
            <a
              href={this.removeSpaces(details.email.raw.url)}
              dangerouslySetInnerHTML={{
                __html: details.email_display_text.text
              }}
              className="text-base inline-block "
            ></a>
            &nbsp; — &nbsp;
            <a
              href={
                "tel:" + this.removeSpaces(details.phone_number.raw[0].text)
              }
              className="text-base phone inline-block"
            >
              {details.phone_number.raw[0].text}
            </a>
          </div>
        </div>
        <div className="absolute left-0 leading-very-tight address-holder">
          <address className="inline-block">
            <a
              href={details.address.raw.url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-base inline-block address not-italic block text-white"
              dangerouslySetInnerHTML={{
                __html: details.address_display_text.text
              }}
            ></a>
          </address>
        </div>
        {details.menu_display_text.raw[0].text && (
          <div className="absolute right-0 bottom-0 leading-very-tight">
            <a
              href={details.menu_link.url}
              rel="noopener noreferrer"
              target="_blank"
              className="text-base relative z-30"
            >
              {details.menu_display_text.raw[0].text}
            </a>
          </div>
        )}
      </div>
    );
  }
}

export default KeyDetails;
