import React from "react";
import { TweenLite, TweenMax } from "gsap";
import Img from "gatsby-image";

class ImageScroller extends React.Component {
  constructor(props) {
    super(props);
    this.containerLeft = React.createRef();
    this.containerRight = React.createRef();
    this.imageWidthCheck = this.imageWidthCheck.bind(this);
  }

  state = {
    length: 5,
    movement: 0
  };

  eventScroll = () => {
    let scrollY = window.scrollY;
    let windowHeight = window.innerHeight;

    this.setState({
      movement: (scrollY / (windowHeight * this.state.length)) * 100
    });
  };

  eventTick = () => {
    const { movement } = this.state;

    let containerLeft = this.containerLeft.current;
    let containerRight = this.containerRight.current;

    TweenMax.to(containerLeft, 1, {
      y: `${movement}%`
    });

    TweenMax.to(containerRight, 1, {
      y: `-${movement}%`
    });
  };

  imageWidthCheck = (width, height) => {
    if (width < height) {
      return "portrait";
    } else {
      return "landscape";
    }
  };

  componentDidMount = () => {
    window.addEventListener("scroll", this.eventScroll);
    TweenLite.ticker.addEventListener("tick", this.eventTick);
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.eventScroll);
    TweenLite.ticker.removeEventListener("tick", this.eventTick);
  };

  render() {
    const { length } = this.state;
    const { imageArrayLeft, imageArrayRight } = this.props;

    return (
      <>
        <div className="relative z-30">
          <div className="fixed inset-y-0 left-0 w-1/2">
            <div
              ref={this.containerLeft}
              className="absolute inset-x-0 bottom-0"
            >
              {imageArrayLeft
                .map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="min-h-screen px-10 flex items-center justify-center py-16"
                    >
                      <div
                        key={index}
                        className={
                          "scroller-image w-full " +
                          this.imageWidthCheck(
                            item.image.localFile.childImageSharp.fluid
                              .presentationWidth,
                            item.image.localFile.childImageSharp.fluid
                              .presentationHeight
                          )
                        }
                      >
                        <Img
                          className="w-full"
                          key={index}
                          alt={item.image.alt}
                          fluid={item.image.localFile.childImageSharp.fluid}
                        />
                      </div>
                    </div>
                  );
                })
                .reverse()}
              <div className="image-spacer"></div>
            </div>
          </div>

          <div className="fixed inset-y-0 right-0 w-1/2">
            <div ref={this.containerRight} className="absolute inset-x-0 top-0">
              <div className="image-spacer"></div>
              {imageArrayRight.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="min-h-screen px-10 flex items-center justify-center py-16"
                  >
                    <div
                      key={index}
                      className={
                        "scroller-image w-full " +
                        this.imageWidthCheck(
                          item.image.localFile.childImageSharp.fluid
                            .presentationWidth,
                          item.image.localFile.childImageSharp.fluid
                            .presentationHeight
                        )
                      }
                    >
                      <Img
                        key={index}
                        alt={item.image.alt}
                        fluid={item.image.localFile.childImageSharp.fluid}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div style={{ height: `${100 * (length + 1)}vh` }}></div>
        </div>
      </>
    );
  }
}

export default ImageScroller;
