import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import ImageScroller from "../ImageScroller";
import ImageScrollerMobile from "../ImageScrollerMobile";
import "../../styles/index.css";
import heroImage from "../../images/bardem-animated-logo.gif";
import Header from "../Header";

class Layout extends React.Component {
  setVhProperty = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  resizeEvent = () => {
    this.setVhProperty();
  };

  componentDidMount = () => {
    this.setVhProperty();
    window.addEventListener("resize", this.resizeEvent);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.resizeEvent);
  };

  render() {
    const { children } = this.props;

    return (
      <StaticQuery
        query={graphql`
          query {
            mobileImages: allPrismicHome {
              nodes {
                data {
                  mobile_images {
                    image {
                      alt
                      localFile {
                        childImageSharp {
                          fluid(maxHeight: 375, quality: 90) {
                            ...GatsbyImageSharpFluid
                            presentationWidth
                            presentationHeight
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            desktopLeftImages: allPrismicHome {
              nodes {
                data {
                  desktop_images_left {
                    image {
                      alt
                      localFile {
                        childImageSharp {
                          fluid(maxHeight: 375, quality: 90) {
                            ...GatsbyImageSharpFluid
                            presentationWidth
                            presentationHeight
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            desktopRightImages: allPrismicHome {
              nodes {
                data {
                  desktop_images_right {
                    image {
                      alt
                      localFile {
                        childImageSharp {
                          fluid(maxHeight: 375, quality: 90) {
                            ...GatsbyImageSharpFluid
                            presentationWidth
                            presentationHeight
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <>
            <div className="font-sans leading-normal bg-gray relative">
              <div className="hidden lg:block">
                <ImageScroller
                  imageArrayLeft={
                    data.desktopLeftImages.nodes[0].data.desktop_images_left
                  }
                  imageArrayRight={
                    data.desktopRightImages.nodes[0].data.desktop_images_right
                  }
                />
              </div>
              <div className="main-container w-full p-4 fixed inset-0 z-30">
                <main className="w-full h-full flex items-center relative">
                  {children}
                </main>
              </div>
              <div className="block lg:hidden">
                <ImageScrollerMobile
                  imageArray={data.mobileImages.nodes[0].data.mobile_images}
                />
              </div>
              <div className="w-full p-4 fixed inset-0">
                <div className="w-full h-full flex items-center relative z-10">
                  <Header heroImage={heroImage} />
                </div>
              </div>
            </div>
          </>
        )}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
